/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    object, string,
} from 'prop-types';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router';
import loadable from '@loadable/component';
import { withStyles } from '@material-ui/core';

import QueryString from 'qs';
import { getBannerCode } from '../../../../state/ducks/App/App-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

import AboveBannerEyebrow from '../AboveBannerEyebrow/AboveBannerEyebrow';

const LoadableGraphqlBannerCodeContainer = loadable(() => import(/* webpackChunkName: "GraphqlBannerCodeContainer" */ '../GraphqlBannerCode/GraphqlBannerCodeContainer'));
const LoadableGraphqlHeader = loadable(() => import(/* webpackChunkName: "GraphqlHeader" */ './GraphqlHeader')); // TODO - SA
const LoadableCheckoutHeader = loadable(() => import(/* webpackChunkName: "CheckoutHeader" */ '../../../pages/Checkout/containers/CheckoutFloral/Partials/CheckoutHeader/View/CheckoutHeader'));
const LoadableCheckoutHeaderFood = loadable(() => import(/* webpackChunkName: "CheckoutHeaderFood" */ '../../../pages/Checkout/containers/CheckoutFood/components/Partials/CheckoutHeader/CheckoutHeader'));

const styles = () => ({
    checkoutHeader: {
        '&.hide-eyebrow': {
            maxWidth: '1270px',
            margin: '0 auto',
        },

        '&.show-eyebrow': {
            '& .checkout-header-container': {
                maxWidth: '1270px',
                margin: '40px auto',
            },
        },
    },
    hideEyeBrow: {
        '& #navbar': {
            top: '0px',
        },
        '& #navMenu': {
            top: '10px',
        },
        '& nav': {
            height: '100px',
        },
    },
});

const HeaderBuilder = ({
    location, brand, deviceType, presentationFamily,
    bannerCode, featureFlags, classes,
}) => {
    const [isEyebrowVisible, setIsEyebrowVisble] = useState(false);

    const searchStr = location.search.slice(1);
    const urlParams = QueryString.parse(searchStr);
    // @intention: Retrieve bannercode from URL params
    // @return: {string}
    const getURLBannerCode = () => urlParams?.r || urlParams?.ref || urlParams?.LocNumber;
    // @intention: check if banner code param exists and not pla
    // @return: {component}

    const renderBanner = () => {
        if (location.pathname.indexOf('/checkout/') < 0) {
            const urlBannerCode = getURLBannerCode();
            let { image_name } = bannerCode || '';
            let bannerPromoCode = bannerCode || {};
            if (!image_name) {
                const cookies = new Cookies();
                const bCookie = cookies.get('banner');

                if (bCookie) {
                    const bAttr = JSON.parse(JSON.stringify(bCookie));
                    image_name = bAttr.e || '';
                    bannerPromoCode = bAttr.c || '';
                }
            }

            if (bannerPromoCode && image_name && (urlParams?.adtype !== 'pla' || !urlParams.ref.includes('pla'))) {
                return <LoadableGraphqlBannerCodeContainer brand={brand} bannerCode={urlBannerCode} />;
            }
        }
        return null;
    };

    const renderHeader = () => {
        let LoadableCheckoutHeaderComponent = LoadableCheckoutHeader;

        if (presentationFamily === 'food') {
            LoadableCheckoutHeaderComponent = LoadableCheckoutHeaderFood;
            if (deviceType === 'mobile') {
                return <LoadableGraphqlHeader brand={brand} ssrDeviceType={deviceType} presentationFamily={presentationFamily} />;
            }
        }

        // Checkout header
        if (['/add-ons', '/wrap-up'].includes(location.pathname)) {
            return <LoadableCheckoutHeaderComponent />;
        }

        if (
            location.pathname.indexOf('/checkout/cart') >= 0
            || location.pathname.indexOf('/checkout/shipping-date') >= 0
            || location.pathname.indexOf('/checkout/shipping') >= 0
            || location.pathname.indexOf('/checkout/payment') >= 0
            || location.pathname.indexOf('/checkout/review-order') >= 0
        ) {
            const step = location.pathname.split('/')[2];
            const currentStep = (step === 'cart') ? 'shoppingcart' : step;
            const currentTab = (step === 'cart') ? false : currentStep;

            return (
                <>
                    {
                        deviceType === 'desktop' && brand.code === 'PLA'
                            ? (
                                <LoadableGraphqlHeader
                                    brand={brand}
                                    ssrDeviceType={deviceType}
                                    presentationFamily={presentationFamily}
                                    setIsEyebrowVisble={setIsEyebrowVisble}
                                />
                            ) : ''
                    }

                    {
                        currentTab
                            ? (<LoadableCheckoutHeaderComponent currentTab={currentTab} currentStep={currentStep} />)
                            : (<LoadableCheckoutHeaderComponent currentStep={currentStep} />)
                    }

                </>
            );
        }

        return (
            <LoadableGraphqlHeader
                brand={brand}
                ssrDeviceType={deviceType}
                presentationFamily={presentationFamily}
                setIsEyebrowVisble={setIsEyebrowVisble}
            />
        );
    };

    const showEyeBrow = () => {
        if (!location.pathname.includes('checkout') && featureFlags['is-covid-eyebrow-enabled']) {
            return <AboveBannerEyebrow location={location} />;
        }
        return null;
    };

    // Precheckout header
    return (
        <header id="global_header_container" className={`${isEyebrowVisible ? 'show-eyebrow' : `hide-eyebrow ${classes.hideEyeBrow}`} ${location.pathname.includes('checkout/') ? classes.checkoutHeader : ''}`} style={{ minHeight: '65px' }}>
            {presentationFamily !== 'food' ? showEyeBrow() : null}
            {renderBanner()}
            {renderHeader()}
        </header>
    );
};

HeaderBuilder.propTypes = {
    brand: object,
    classes: object.isRequired,
    location: object.isRequired,
    presentationFamily: string.isRequired,
    deviceType: string,
    bannerCode: string,
    featureFlags: object.isRequired,
};

HeaderBuilder.defaultProps = {
    brand: {},
    deviceType: '',
    bannerCode: null,
};

const mapStateToProps = (state) => ({
    bannerCode: getBannerCode(state),
    brand: getBrand(state),
    featureFlags: getFeatureFlags(state),
});

export default (withStyles(styles)(withRouter(
    connect(
        mapStateToProps,
    )(HeaderBuilder),
)));
