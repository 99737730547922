/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { object } from 'prop-types';
import { usePrev } from '../../../helpers/hooks/usePrev';

const styles = (theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        background: '#c5e0b3',
        fontSize: '15px',
        padding: '8px 16px',
        justifyContent: 'center',
        [theme.breakpoints.down('600')]: {
            fontSize: '12px',
            textAlign: 'center',
            padding: '8px 0px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '11px',
        },
    },
    para: {
        lineHeight: '1.2',
        letterSpacing: '.04em',
        fontWeight: '400',
        [theme.breakpoints.down('xs')]: {
            letterSpacing: '-0.2px',
        },
        [theme.breakpoints.down('sm')]: {
            letterSpacing: '-0.7px',
        },
    },
    learnMoreText: {
        lineHeight: '1.2',
        letterSpacing: '.04em',
        fontWeight: '400',
        color: theme.palette.colorPrimary,
        textDecoration: 'underline',
    },

});

const AboveBannerEyebrow = ({ classes, location }) => {
    // const hasBeenMounted = useRef(false);
    const [displayBanner, changeBannerDisplayState] = useState(true);
    const oldPathname = usePrev(location.pathname);
    useEffect(() => {
        if (displayBanner && oldPathname !== undefined && location.pathname !== oldPathname) {
            changeBannerDisplayState(false);
        }
    }, [location.pathname]);
    const eyeBrowCopy = 'We are thinking of you in these unprecedented times and continue to deliver nationwide with safety as our top priority';
    const noSignature = 'No contact is necessary for deliveries.';
    const learnMore = 'Learn More';
    return displayBanner ? (
        <Grid container className={classes.container}>
            <span className={classes.para}>{eyeBrowCopy} - <b>&nbsp;{noSignature}&nbsp;</b>
                <Link className={classes.learnMoreText} to="/a-message-to-our-customers-400216497">{learnMore}</Link>
            </span>
        </Grid>
    ) : null;
};

AboveBannerEyebrow.propTypes = {
    classes: object.isRequired,
    location: object.isRequired,
};

export default withStyles(styles)(AboveBannerEyebrow);
