/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { PureComponent } from 'react';
import {
    object, string, func, bool, shape,
} from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import loadable from '@loadable/component';

// header ui components
import Media from 'react-media';

// helpers, redux, gql
import { getAddressSource } from '../../../../state/ducks/App/App-Selectors';
import { setLanguageName, setCurrencyName } from '../../../../state/ducks/Member/ducks/Auth/helper/helper';
import { setDataDictionary, setCurrency } from '../../../../state/ducks/App/App-Actions';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

// header ui components
const LoadableDesktopGraphqlHeader = loadable(() => import(/* webpackChunkName: "DesktopGraphqlHeader" */ './DesktopGraphqlHeader'));
const LoadableMobileGraphqlHeader = loadable(() => import(/* webpackChunkName: "MobileGraphqlHeader" */ './MobileGraphqlHeader'));

const TABLET_WIDTH = 1025;

class GraphqlHeader extends PureComponent {
    componentDidMount() {
        const { featureFlags, setCurrencyData } = this.props;
        if (featureFlags['is-internationalization-enabled']) {
            if (featureFlags['is-only-language-English-allowed']) {
                this.languageChange('/en');
            } else if (featureFlags['is-only-language-French-allowed']) {
                this.languageChange('/fr');
            }
            if (featureFlags['is-only-cad-currency-allowed']) {
                // set the currency to CAD
                setCurrencyName('CAD'); // session storage
                setCurrencyData({ currencyName: 'CAD', currencySymbol: 'C$' }); // redux
            } else if (featureFlags['is-only-usd-currency-allowed']) {
                // set the currency to USD
                setCurrencyName('USD'); // session storage
                setCurrencyData({ currencyName: 'USD', currencySymbol: '$' }); // redux
            } else if (window?.location?.host?.includes('1800flowers.ca')) {
                // if both flags are disabled, set currency to CAD by default
                setCurrencyData({ currencyName: 'CAD', currencySymbol: 'C$' }); // redux
                setCurrencyName('CAD'); // session storage
            }
        }
    }

    languageChange = (language) => {
        const {
            history,
            setDataDictionaryToStore,
        } = this.props;
        if (language) {
            setLanguageName(language);
            if (language === '/en') {
                setDataDictionaryToStore({});
            }
        }
        const path = window?.location?.pathname;
        if (path && path === '/') {
            history.push({
                pathname: language,
            });
        } else {
            const pathNameOfPage = history?.location?.pathname + history?.location?.search;
            let languageCode = language;
            if (language.indexOf('/') > -1) {
                languageCode = languageCode.replace('/', '');
            }
            const pathname = pathNameOfPage.replace(window?.location?.pathname?.split('/')[1], languageCode);
            if (pathname) {
                // just for pdp
                if (pathname.split('/')[2] === 'p') {
                    history.push(pathname, { ...history?.location?.state, from: history?.location?.pathname });
                } else {
                    history.push(pathname);
                }
            }
        }
    };

    render() {
        const {
            brand,
            presentationFamily,
            ssrDeviceType,
            location,
            addressSource,
            setIsEyebrowVisble,
        } = this.props;

        /**
         * @description determine layout of header menu
         * @param {string} source
         * @returns {string} layout type
         */
        const determineLayout = () => {
            if (presentationFamily !== 'growthbrand') return ''; // SCI only exists for growthbrand site. If not on growthbrand, we can skip this bit of logic.
            const { pathname } = location;
            if (addressSource === 'SCI' || pathname === '/fhdirect') return 'SCI';
            return ''; // Defaults to `Normal`
        };

        // tablet needs mobile header
        const checkIfTablet = (deviceType) => {
            if (deviceType === 'tablet') {
                return 'mobile';
            }
            return deviceType;
        };

        // TODO: Refactor for cleaner code.
        return (
            <>
                <Media
                    query={`(min-width: ${TABLET_WIDTH}px)`}
                    defaultMatches={checkIfTablet(ssrDeviceType) === 'desktop'}
                    render={() => (
                        <LoadableDesktopGraphqlHeader brand={brand} layout={determineLayout()} presentationFamily={presentationFamily} languageChange={this.languageChange} setIsEyebrowVisble={setIsEyebrowVisble} />
                    )}
                />

                <Media
                    query={`(max-width: ${TABLET_WIDTH}px)`}
                    defaultMatches={checkIfTablet(ssrDeviceType) === 'mobile'}
                    render={() => (
                        <LoadableMobileGraphqlHeader brand={brand} layout={determineLayout()} presentationFamily={presentationFamily} languageChange={this.languageChange} setIsEyebrowVisble={setIsEyebrowVisble} />
                    )}
                />
            </>
        );
    }
}

GraphqlHeader.propTypes = {
    brand: object.isRequired,
    presentationFamily: string.isRequired,
    ssrDeviceType: string.isRequired,
    location: object.isRequired,
    addressSource: string,
    history: object.isRequired,
    setDataDictionaryToStore: func.isRequired,
    featureFlags: shape({
        'is-only-language-English-allowed': bool,
        'is-only-language-French-allowed': bool,
        'is-only-cad-currency-allowed': bool,
        'is-only-usd-currency-allowed': bool,
    }).isRequired,
    setCurrencyData: func.isRequired,
    setIsEyebrowVisble: func,
};

GraphqlHeader.defaultProps = {
    addressSource: '',
    setIsEyebrowVisble: () => {},
};

const mapStateToProps = (state) => ({
    addressSource: getAddressSource(state),
    featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = (dispatch) => ({
    setDataDictionaryToStore: bindActionCreators(setDataDictionary, dispatch),
    setCurrencyData: bindActionCreators(setCurrency, dispatch),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
);

export default enhance(GraphqlHeader);
